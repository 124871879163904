<template>
<main>
   
    <div class="modal fade" id="modalInsumos" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Notas de Cargo</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="nuevo-nota-cargo-tab" data-toggle="tab" href="#nuevo-nota-cargo" role="tab" aria-controls="nuevo-nota-cargo" aria-selected="true">Registrar Insumos</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="li-tab" data-toggle="tab" href="#listado-nota-cargo" role="tab" aria-controls="list-nota-cargo" aria-selected="false">Listado Notas de Cargo</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="nuevo-nota-cargo" role="tabpanel" aria-labelledby="nuevo-nota-cargo-tab">
                        <create v-on:cargar-nota-cargo="cargar" v-on:newNotaCargo="$emit('newNotaCargo')" :orden="orden" :usuario="usuario"></create>
                    </div>
                    <div class="tab-pane fade" id="listado-nota-cargo" role="tabpanel" aria-labelledby="listado-nota-cargo-tab">
                        <list ref="listNotaCargo"  :orden="orden" v-on:notaCargoEliminada="$emit('notaCargoEliminada')"></list>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal"><i class="far fa-times-circle fa-fw"></i> Cerrar</button>
            </div>
            </div>
        </div>
    </div>
</main>
   
</template>
<script>
import create from './create';
import list from './list';
import $ from "jquery";
export default {
    components:{create,list},
    props: ['usuario'],
    data() {
        return {
            orden: {},
        }
    },
    methods: {
       async cargar(){
            this.$refs.listNotaCargo.cargarInsumos(this.orden.id);
        },
        openModal(orden){
            this.$nextTick( () => {
                this.orden = orden;
                $("#modalInsumos").modal("show");
                this.cargar(this.orden.id);
            });
        }
    },
}
</script>

