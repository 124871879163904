<template>
    <main>
        <div class="alert alert-warning mt-2" v-if="!aplicaciones.length">
            No se han cargado aplicaciones...
        </div>
        <div v-else class="card shadow-none mt-2" v-for="item in aplicaciones" :key="item.id">
            <div class="card-header bg-gray text-bold">
                <div class="row">
                    <div class="col-4 ">
                        Aplicación No. {{item.id}} 
                    </div>
                    <div class="col-8 d-flex justify-content-end">
                        <span class="text-black"><i class="fa fa-user fa-fw"></i>{{item.user.name}}</span>
                        <button v-if="item.user_id===user.id" @click="eliminar(item.id);" class="btn btn-danger lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-trash-alt"></i>
                        </button>
                        <!-- <button @click="imprimir(item.id);" class="btn btn-secondary lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-print fa-fw"></i>
                        </button> -->
                    </div>
                </div>
            </div>
            <div class="card-body" >
                <div class="table-responsive">
                    <div class="d-flex justify-content-end">
                        <span class="font-weight-bolder"> <i class="fa fa-calendar fa-fw"></i> {{item.fecha_aplicacion | formatDate}} {{item.hora_aplicacion | timeFormat}}</span>
                    </div>
                    <table class="table table-borderless mb-0 table-sm mt-2">
                        <thead class="border-bottom thead-light">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Medicamento</th>
                                <th scope="col">Cantidad</th> 
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom">
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.servicio.codigo }}
                                        {{ item.servicio.descripcion }}
                                        <div class="small text-muted d-none d-md-block">
                                            Observación: {{ item.observaciones }}
                                        </div>
                                    </div>
                                </td>
                                <td><span class="badge badge-secondary-soft badge-marketing">{{ item.cantidad }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import Toast from '../../../../../components/common/utilities/toast';
import aplicacionMedicamentoService from '../../../../../services/aplicacionMedicamentoService';
import Swal from 'sweetalert2';
export default {
    props: ['ItemOrden'],
    data(){
        return {
            aplicaciones:{}
        }
    },
    methods: {
        async cargarAplicaciones(id){
            const response = await aplicacionMedicamentoService.showByIdOrden(id);
            this.aplicaciones= response.data;
        },
        imprimir(id_aplicacion){
            window.open(aplicacionMedicamentoService.print(id_aplicacion));
        },
        async eliminar(id) {
            const result = await Swal.fire({
                title :  "¿Está seguro de eliminar esta aplicacion?",
                text : "No se podrá revertir los cambios",
                showCancelButton : true,
                showConfirmButton : true,
                confirmButtonText : 'Sí',
                cancelButtonText : 'No',
                icon : "warning",
                showLoaderOnConfirm : true,
                preConfirm: async () => {
                    try{
                        await aplicacionMedicamentoService.delete(id);
                    }catch (e) {
                        console.error(e);
                        Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                    }
                },
            });

            if(result.isConfirmed){
                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con exito'
                });
                this.cargarAplicaciones(this.ItemOrden.id);
                this.$emit('aplicacionEliminada');
            }            
        },
    },
    async created(){
        try {
            await this.cargarAplicaciones(this.ItemOrden.id);
        } catch (error) {
            console.error(error);
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>