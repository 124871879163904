<template>
    <main>
        <div class="alert alert-warning mt-2" v-if="!notas.length">
            No se han cargado insumos...
        </div>
        <div v-else class="card shadow-none mt-2" v-for="item in notas" :key="item.id">
            <div class="card-header bg-gray text-bold">
                <div class="row">
                    <div class="col-4 ">
                        Nota de cargo No. {{item.id}} 
                    </div>
                    <div class="col-8 d-flex justify-content-end">
                        <span class="text-black"><i class="fa fa-user fa-fw"></i>{{item.user.name}}</span>
                        <button v-if="item.user_id===user.id" @click="eliminar(item.id);" class="btn btn-danger lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-trash-alt"></i>
                        </button>
                        <button @click="imprimir(item.id);" class="btn btn-secondary lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-print fa-fw"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body" >
                <div class="table-responsive">
                    <div class="d-flex justify-content-end">
                        <span class="font-weight-bolder"> <i class="fa fa-calendar fa-fw"></i> {{item.fecha | formatDate}} {{item.hora | timeFormat}}</span>
                    </div>
                    <table class="table table-borderless mb-0 table-sm mt-2">
                        <thead class="border-bottom thead-light">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Insumo</th>
                                <th scope="col">Cantidad</th> 
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom" v-for="(detalle) in item.items" :key="detalle.id">
                                <td>
                                    <div class="font-weight-bold">
                                        {{ detalle.servicio.codigo }}
                                        {{ detalle.servicio.descripcion }}
                                        <div class="small text-muted d-none d-md-block">
                                            Observación: {{ detalle.observaciones }}
                                        </div>
                                    </div>
                                </td>
                                <td><span class="badge badge-secondary-soft badge-marketing">{{ detalle.cantidad }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import Toast from '../../../../../components/common/utilities/toast';
import notasCargoService from '../../../../../services/notasCargoService';
import Swal from 'sweetalert2';
export default {
    props: ['orden'],
    data(){
        return {
            notas:{}
        }
    },
    methods: {
        async cargarInsumos(id){
            const response = await notasCargoService.showByIdOrden(id);
            this.notas= response.data;
        },
        imprimir(id_nota){
            window.open(notasCargoService.print(id_nota));
        },
        async eliminar(id) {
            const result = await Swal.fire({
                title :  "¿Está seguro de eliminar esta nota de cargo?",
                text : "No se podrá revertir los cambios",
                showCancelButton : true,
                showConfirmButton : true,
                confirmButtonText : 'Sí',
                cancelButtonText : 'No',
                icon : "question",
                showLoaderOnConfirm : true,
                preConfirm: async () => {
                    try{
                        await notasCargoService.delete(id);
                    }catch (e) {
                        console.error(e);
                        Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
                    }
                },
            });

            if(result.isConfirmed){
                Toast.fire({
                    icon: 'success',
                    title: 'Datos eliminados con exito'
                });
                this.cargarInsumos(this.orden.id);
                this.$emit('notaCargoEliminada');
            }            
        },
    },
    async created(){
        try {
            await this.cargarInsumos(this.orden.id);
        } catch (error) {
            console.error(error);
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        }
    }
}
</script>