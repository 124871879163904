<template>
    <main>
        <div class= "card shadow-none mt-4" v-for="(orden,index) in ordenes" :key="index" >
            <div class="card-header bg-light">
                <div class="row">
                    <div class="col-6 ">
                        #{{index+1}} Orden No. {{ orden.id }} 
                        <div class="d-flex">
                            <span class="text-danger" v-if="!orden.notas_cargo.length"><i>No se han cargado insumos...</i></span>
                            <span class="text-success" v-else><i>Esta orden ya tiene insumos cargados</i></span>
                        </div>
                    </div>
                    <div class="col-6 d-flex justify-content-end">
                        <span class="text-black"><i class="fa fa-user fa-fw"></i>{{orden.user.name}}</span>
                        <button @click="imprimir(orden.id);" class="btn btn-secondary lift mx-2 shadow-sm btn-sm" >
                            <i class="fa fa-print fa-fw"></i> Imprimir
                        </button>
                        <button class="btn btn-success btn-sm " @click="openModalInsumos(orden)">
                            <i class="fas fa-plus fa-fw"></i>
                            Cargar Insumos
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-end">
                    <span class="font-weight-bolder"> <i class="fa fa-calendar fa-fw"></i> {{orden.created_at | formatDateTime}}</span>
                </div>
                <div class="card shadow-none mb-1" v-for="item in orden.items" :key="item.id">
                    <div class="card-body d-flex flex-column">
                        <h6 class="font-weight-bolder">Medicamento: <span class="text-primary">{{item.servicio.descripcion}}</span></h6>
                        <h6 class="font-weight-bolder">Cantidad: <span class="badge badge-secondary-soft badge-marketing">{{item.cantidad}}</span></h6>
                        <h6 ><p class="text-cursive text-bold">Observaciones: <i>{{item.observacion}}</i></p></h6>
                        <div class="d-flex justify-content-end">
                            <span class="text-danger" v-if="!item.aplicaciones.length"><i>No se han registrado aplicaciones...</i></span>
                            <span class="text-success" v-else><i>Aplicación de medicamento registrado</i></span>
                        </div>
                        <div class="form-group d-flex justify-content-end mt-2">
                            <button class="btn btn-success btn-sm " @click="openModalAplicaciones(item)">
                                <i class="fas fa-plus fa-fw"></i>
                                Aplicación de Médicamentos
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalAplicacionMedicamento  :usuario="usuario" ref="modalAplicaciones" v-on:cargar-aplicaciones="cargarOrdenes" v-on:aplicacionEliminada="cargarOrdenes" v-on:newAplicacion="cargarOrdenes"></modalAplicacionMedicamento>
        <modalNotaCargo  :usuario="usuario" ref="modalNota" v-on:cargar-ordenes="cargarOrdenes" v-on:notaCargoEliminada="cargarOrdenes" v-on:newNotaCargo="cargarOrdenes"></modalNotaCargo>
    </main>
</template>
<script>

import ordenClinicaService from '../../../../services/ordenClinicaService';
import Toast from '../../../../components/common/utilities/toast';
import historiaClinicaService from '../../../../services/historiaClinicaService';
import modalAplicacionMedicamento from './aplicacionMedicamento/index';
import modalNotaCargo from './notaCargo/index';

export default {
    props: ['idHistoria','finalizada','usuario'],
    components:{modalAplicacionMedicamento,modalNotaCargo},
    data(){
        return {
            ordenes:{},
        }
    },
    methods: {
        async cargarOrdenes(){
            const response = await ordenClinicaService.showOrdenByHcAndType({
                id_historia:this.idHistoria,
                type:'medicamento'
            });
            this.ordenes= response.data;
        },
        imprimir(id_orden){
            window.open(historiaClinicaService.orden(id_orden));
        },
        openModalAplicaciones(itemOrden){
            this.$refs.modalAplicaciones.openModal(itemOrden);
        },
        openModalInsumos(orden){
            this.$refs.modalNota.openModal(orden);
        }
    },
    async created(){
        try {
            this.LoaderSpinnerShow();
            await this.cargarOrdenes();
            this.LoaderSpinnerHide();
        } catch (error) {
            console.error(error);
            this.LoaderSpinnerHide();
            Toast.fire({
                icon: 'error',
                title: 'Ocurrio un error al procesar la solicitud'
            });
        }
    }
}
</script>