<template>
    <div>
        <div class="row mt-2">
            <div class="col-lg-12">
                <span v-if="ItemOrden.servicio" class="badge badge-secondary-soft badge-marketing font-weight-bolder">
                    <h5>{{ ItemOrden.servicio.descripcion }}</h5>
                </span>
            </div>
            <div class="col-lg-12 mt-2">
                <span v-if="ItemOrden.servicio" class="badge badge-primary-soft badge-marketing font-weight-bolder">
                    <h5>Cantidad: {{ ItemOrden.cantidad }}</h5>
                </span>
            </div>
            <div class="col-lg-4 form-group mt-3">
                <label class="small mb-1 font-weight-bolder">Fecha de Aplicación <small
                        class="text-danger">*</small></label>
                <input v-model="aplicacion.fecha_aplicacion" class="form-control form-control-sm" type="date" :class="{
                    'is-invalid': $v.aplicacion.fecha_aplicacion.$error && $v.aplicacion.fecha_aplicacion.$dirty
                }" />
                <span class="invalid-feedback" v-if="!$v.aplicacion.fecha_aplicacion.required">
                    Este campo es requerido
                </span>
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="small mb-1 font-weight-bolder">Hora de Aplicación <small
                        class="text-danger">*</small></label>
                <input type="time" class="form-control form-control-sm" v-model="aplicacion.hora_aplicacion"
                    :class="{ 'is-invalid': $v.aplicacion.hora_aplicacion.$error && $v.aplicacion.hora_aplicacion.$dirty }">
            </div>
            <div class="col-lg-4">
                <label class="small mb-1 font-weight-bolder">Cantidad <small class="text-danger">*</small></label>
                <input v-model="aplicacion.cantidad" class="form-control form-control-sm" type="number" :class="{
                    'is-invalid': $v.aplicacion.cantidad.$error && $v.aplicacion.cantidad.$dirty
                }" />
                <span class="invalid-feedback" v-if="!$v.aplicacion.cantidad.required">
                    Este campo es requerido
                </span>
            </div>
            <div class="col-lg-12">
                <label class="form-label font-weight-bolder"><i class="far fa-comment-alt fa-fw"></i> Observaciones
                    <small class="text-danger">*</small></label>
                <div class="">
                    <textarea v-model="aplicacion.observaciones" class="form-control"></textarea>
                </div>
            </div>
        </div>
        <button class="btn btn-success btn-sm mt-2" @click="guardar()">
            <i class="fas fa-save"></i>&nbsp;Guardar
        </button>
    </div>
</template>
<script>
import aplicacionMedicamentoService from '../../../../../services/aplicacionMedicamentoService';
import { required } from "vuelidate/lib/validators";
import Toast from '../../../../../components/common/utilities/toast';
import dayjs from "dayjs";

export default {
    props: ['usuario','ItemOrden'],
    data() {
        return {
            aplicacion: {
                id_item_orden: '',
                id_orden: '',
                id_usuario:'',
                id_servicio: '',
                fecha_aplicacion: '',
                hora_aplicacion: '',
                observaciones: '',
                cantidad: '',
            },
        }
    },
    validations() {
        return {
            aplicacion: {
                fecha_aplicacion: { required },
                cantidad: { required },
                hora_aplicacion: { required },
            }
        }
    },
    methods: {
        async guardar() {
            try {
                this.$v.aplicacion.$touch();
                if (this.$v.aplicacion.$invalid) {
                    return
                }

                this.aplicacion.id_orden = this.ItemOrden.id_orden;
                this.aplicacion.id_item_orden = this.ItemOrden.id;
                this.aplicacion.id_servicio = this.ItemOrden.servicio.id;
                this.aplicacion.id_usuario = this.usuario.id;

                this.LoaderSpinnerShow();
                await aplicacionMedicamentoService.store(this.aplicacion);
                this.limpiarCampos();
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                this.$emit('cargar-aplicaciones');
                this.$emit('newAplicacion');
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos() {
            this.aplicacion = {};
        },
    },
    created() {
        const date_now = dayjs();
        this.aplicacion.fecha_aplicacion = date_now.format("YYYY-MM-DD");
        this.aplicacion.hora_aplicacion = date_now.format("HH:mm");
    }
}
</script>
