<template>
<main>
   
    <div class="modal fade" id="modalAplicaciones" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Aplicación</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="n-tab" data-toggle="tab" href="#nuevo-aplicacion" role="tab" aria-controls="new-aplicacion" aria-selected="true">Registrar Aplicación</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="li-tab" data-toggle="tab" href="#listado-aplicacion" role="tab" aria-controls="list-aplicacion" aria-selected="false">Listado Aplicaciones</a>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="nuevo-aplicacion" role="tabpanel" aria-labelledby="nuevo-aplicacion-tab">
                        <create v-on:cargar-aplicaciones="cargar" v-on:newAplicacion="$emit('newAplicacion')" :ItemOrden="ItemOrden" :usuario="usuario"></create>
                    </div>
                    <div class="tab-pane fade" id="listado-aplicacion" role="tabpanel" aria-labelledby="listado-aplicacion-tab">
                            <list ref="listAplicaciones" v-on:aplicacionEliminada="$emit('aplicacionEliminada')" :ItemOrden="ItemOrden"></list>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light btn-sm" data-dismiss="modal"><i class="far fa-times-circle fa-fw"></i> Cerrar</button>
            </div>
            </div>
        </div>
    </div>
</main>
   
</template>
<script>
import create from './create';
import list from './list';
import $ from "jquery";
export default {
    components:{create,list},
    props: ['usuario'],
    data() {
        return {
            ItemOrden: {},
        }
    },
    methods: {
       async cargar(){
            this.$refs.listAplicaciones.cargarAplicaciones(this.ItemOrden.id);
        },
        openModal(itemOrden){
            this.$nextTick( () => {
                this.ItemOrden = itemOrden;
                $("#modalAplicaciones").modal("show"); 
                this.cargar(itemOrden.id);  
            });
        }
    },
}
</script>

