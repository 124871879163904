<template>
    <div>
        <div class="row mt-2">
            <div class="col-lg-4 form-group">
                <label class="small mb-1 font-weight-bolder">Fecha <small class="text-danger">*</small></label>
                <input v-model="fecha" class="form-control form-control-sm" type="date" :class="{
                    'is-invalid': $v.fecha.$error && $v.fecha.$dirty
                }" />
            </div>
            <div class="form-group col-lg-4 col-md-12 col-sm-12">
                <label class="small mb-1 font-weight-bolder">Hora<small class="text-danger">*</small></label>
                <input type="time" class="form-control form-control-sm" v-model="hora"
                    :class="{ 'is-invalid': $v.hora.$error && $v.hora.$dirty }">
            </div>
        </div>
        <div class="card shadow-none">
            <div class="card-header"><i class="fa fa-list fa-fw"></i> Detalle de Insumos</div>
            <div class="card-body">
                <div class="form-group mt-2">
                    <label class="form-label">Insumo <small class="text-danger">*</small></label>
                    <v-select-servicio :groups="[9]" v-model="item.servicio" mode="object"
                        :class="{ 'is-error': $v.item.servicio.$error }">
                    </v-select-servicio>
                    <span v-if="!$v.item.servicio.required && $v.item.servicio.$dirty" class="text-danger">
                        <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                    </span>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2">
                        <label class="form-label">Cantidad <small class="text-danger">*</small></label>
                        <input type="number" v-model="item.cantidad" class="form-control form-control-sm"
                            :class="{ 'is-invalid': $v.item.cantidad.$error && $v.item.cantidad.$dirty }">
                    </div>
                    <div class="form-group col-md-8">
                        <label class="form-label">Observaciones <small class="text-danger">*</small></label>
                        <input type="text" class="form-control form-control-sm" v-model="item.observacion"
                            :class="{ 'is-invalid': $v.item.observacion.$error && $v.item.observacion.$dirty }">
                    </div>
                    <div class=" mt-4 py-2 col-md-2  text-center">
                        <button class="btn btn-primary btn-sm" @click="agregarServicio()">
                            <i class="fas fa-plus fa-fw"></i>Agregar
                        </button>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-borderless mb-0 table-sm">
                        <thead class="border-bottom">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Insumo</th>
                                <th scope="col">Cantidad</th> 
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom" v-for="(item, index) in items" :key="item.id">
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.servicio.codigo }}
                                        {{ item.servicio.descripcion }}
                                        <div class="small text-muted d-none d-md-block">
                                            Observación: {{ item.observacion }}
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.cantidad }}</td>
                                <td>
                                    <button class="btn btn-transparent rounded-circle" @click="removerServicio(index)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <button class="btn btn-success btn-sm mt-2" @click="guardar()">
            <i class="fas fa-save"></i>&nbsp;Guardar
        </button>
    </div>
</template>
<script>
import notasCargoService from '../../../../../services/notasCargoService';
import { required } from "vuelidate/lib/validators";
import Toast from '../../../../../components/common/utilities/toast';
import dayjs from "dayjs";
import { isEmpty } from 'lodash';
import vSelectServicio from "../../../../../components/common/VSelectServicio";

export default {
    props: ['usuario', 'orden'],
    components: { vSelectServicio },
    data() {
        return {
            fecha: '',
            hora: '',
            item: {
                cantidad: '1',
                observacion: 'Ninguna',
                servicio: {},
            },
            items: [],
        }
    },
    validations() {
        return {
            fecha: { required },
            hora: { required },
            item: {
                cantidad: { required },
                observacion: { required },
                servicio: { required },
            }
        }
    },
    methods: {
        agregarServicio() {
            this.$v.item.$touch();
            if (this.$v.item.$invalid) {
                return;
            }
            this.items.push({ ...this.item });
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        async guardar() {
            try {
                this.$v.fecha.$touch();
                if (this.$v.hora.$invalid) {
                    return
                }
                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar por lo menos un insumo'
                    });
                    return;
                }

                let nota = {
                    fecha: this.fecha,
                    hora: this.hora,
                    id_orden: this.orden.id,
                    item_nota_cargo:this.items
                }

                this.LoaderSpinnerShow();
                await notasCargoService.store(nota);
                this.limpiarCampos();
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
                this.$emit('cargar-nota-cargo');
                this.$emit('newNotaCargo');
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        limpiarCampos() {
            this.nota = {};
            this.item.servicio = {};
            this.items = [];
        },
    },
    created() {
        const date_now = dayjs();
        this.fecha = date_now.format("YYYY-MM-DD");
        this.hora = date_now.format("HH:mm");
    }
}
</script>
